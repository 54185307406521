:root {
  --frame-main-color: rgb(218, 255, 170);
  --frame-main-hover-color: rgb(218, 255, 170);
  --button-primary-background: var(--frame-main-color);
}
.App {
  display: grid;
  grid-template-columns: auto 200px;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 20px;
  /*background-color: white;*/
}

.Column {
  color: white;
  max-width: 1000px;
}

.Title {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  color: black;
  background-color: rgb(218, 255, 170);
}

.Content {
  height: calc(100vh - 260px);
  overflow-y: scroll;
  margin-bottom: 20px;
}
::-webkit-scrollbar {
  display: none;
}
.upload_button {
  background: var(--button-primary-background, linear-gradient(90deg, rgb(218, 255, 170), rgb(218, 255, 170)));
  /* #389adc */
  /*width: 80px;*/
  /*height: 40px;*/
  display: flex;
  cursor: pointer;
  font-size: 13px;
  margin-bottom: 20px;
  /*position: relative;*/
  /*background: #389adc;*/
  padding: 20px;
  color: black;
  /*text-align: center;*/
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: 10px;
  /*overflow: hidden;*/
}
/*选择模型*/
.modelSelector{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 10px;
  /*background-color: #1b1b1d;*/
}
/*选择文件部分*/
.inputContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 10px;
  /*background-color: #1b1b1d;*/
}


/*.custom-file-input {*/
/*  !*width: 250px;*!*/
/*  padding: 4px;*/
/*}*/
.custom-file-input::after{
  color: white; /* 修改颜色为蓝色，根据需要选择颜色值 */
}