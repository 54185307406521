.wrapper {
  width: 100%;
  height: 45px;
  background-color: #ff9d84;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
}

.wrapper:hover {
  cursor: pointer;
  background-color: #ff886b;
}