

.wrapper {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: rgb(218, 255, 170);
  /*background: var(--button-primary-background, linear-gradient(90deg, #00b4ff, #1e88ff));*/
}

.wrapper:hover {
  cursor: pointer;
  background-color: rgb(200, 253, 130);
}